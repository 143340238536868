export default function Info() {
    return <div id="Home-Info">
        <h2>Nichathan Gaming</h2>
        <p>
            Nichathan Gaming is an independant company conceived by Johnathan Nichols 
            in 2015 but only active since the beginning of 2023. Nichathan Gaming provides
            programming solutions for websites, applications and computer games.
        </p>
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
            <div style={{margin:'1rem'}}>
                <h3>
                    Website Support
                </h3>
                <ul>
                    <li>MERN</li>
                    <li>FERN</li>
                    <li>Wordpress</li>
                </ul>
            </div>
            <div style={{margin:'1rem'}}>
                <h3>
                    Application and Game Support
                </h3>
                <ul>
                    <li>
                        <h4>Styles</h4>
                        <ul>
                            <li>2D</li>
                            <li>3D</li>
                        </ul>
                    </li>
                    <li>
                        <h4>IDE's</h4>
                        <ul>
                            <li>Unity</li>
                            <li>Android Studio</li>
                            <li>Python</li>
                        </ul>
                    </li>
                    <li>
                        <h4>Platforms</h4>
                        <ul>
                            <li>Windows</li>
                            <li>Android</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}