import { useEffect, useRef } from 'react'
import './Glitch.css'

export default function Glitch({ id, className, params, onClick }) {
    const ind0 = useRef()
    const ind1 = useRef()
    const ind2 = useRef()

    useEffect(() => {
        try {
            ind0.current.style.setProperty('--index', 0)
            ind1.current.style.setProperty('--index', 1)
            ind2.current.style.setProperty('--index', 2)
        } catch (e) {

        }
    }, [])

    var styles={}
    if(params.type==='img')styles={lineHeight:'1rem'}

    return <div
        id={id}
        style={styles}
        className={"stack " + className}
        onClick={onClick}
        onMouseEnter={() => {
            ind0.current.className = 'glitch-effect'
            ind1.current.className = 'glitch-effect'
            ind2.current.className = 'glitch-effect'
        }}
        onMouseLeave={() => {
            ind0.current.className = 'stack-element-show'
            ind1.current.className = 'stack-element-hide'
            ind2.current.className = 'stack-element-hide'
        } }
    >
        <span className='stack-element-show' ref={ind0}>{params}</span>
        <span className='stack-element-hide' ref={ind1}>{params}</span>
        <span className='stack-element-hide' ref={ind2}>{params}</span>
    </div>
}