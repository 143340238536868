import { useState } from "react";
import Glitch from "../../../animations/Glitch"
import emailjs from '@emailjs/browser';
import { useNotificationInfo } from "../../../contexts/NotificationContext";

export default function Contact() {
    const [emailState, setEmailState] = useState("")
    const [nameState, setNameState] = useState("")
    const [budgetState, setBudgetState] = useState("")
    const [descriptionState, setDescriptionState] = useState("")
    const {setMessageState} = useNotificationInfo()

    return <div id="Home-Contact">
        <input 
            type="email"
            id="Home-Contact-Email"
            name="email"
            placeholder="Email"
            value={emailState}
            onChange={(e)=>{setEmailState(""+e.target.value)}}
        />
        <input 
            type="text"
            id="Home-Contact-Name"
            name="name"
            placeholder="Name"
            value={nameState}
            onChange={(e)=>{setNameState(""+e.target.value)}}
        />
        <input 
            type="number"
            id="Home-Contact-Budget"
            name="budget"
            placeholder="Budget (USD)"
            value={budgetState}
            onChange={(e)=>{setBudgetState(""+e.target.value)}}
        />
        <textarea 
            id="Home-Contact-Description"
            name="Description"
            placeholder="Describe what you would like done.
If you have any links to images, videos or websites to support your description, include them.
Please include an expected completion date as well.
You can expect a response as soon as possible."
            value={descriptionState}
            onChange={(e)=>{setDescriptionState(""+e.target.value)}}
        />
        <Glitch  
            className='Home-Contact-Button'
            params='Submit'
            onClick={() => { 
                var canSend = true
                var errorMessage=""
                if(emailState.length<5){
                    canSend=false
                    errorMessage+="Please add an email.\n"
                }
                if(nameState.length<3){
                    canSend=false
                    errorMessage+="Please add a name.\n"
                }
                if(budgetState.length<1){
                    canSend=false
                    errorMessage+="Please add a budget.\n"
                }
                if(descriptionState.length<20){
                    canSend=false
                    errorMessage+="Please add a description.\n"
                }

                if(canSend){
                    const templateParams = {
                        from_name: nameState,
                        message: "email: "+emailState+"\nbudget: "+budgetState+"\ndescription: "+descriptionState
                    };
    
                    emailjs.send("service_dcr09nl","template_5rasxh8", templateParams,'3NghfFykBLl7v25Vx');

                    setMessageState("Email Sent")
                    setBudgetState("")
                    setDescriptionState("")
                    setEmailState("")
                    setNameState("")
                }else{
                    setMessageState(errorMessage)
                }
            }}
        />    
    </div>    
}