import { useEffect, useState } from "react";
import { useNotificationInfo } from "../../contexts/NotificationContext";

export default function Commission(){
    const {setMessageState} = useNotificationInfo()
    const [pageState, setPageState] = useState(<></>)

    useEffect(()=>{
        const path = window.location.pathname.substring(13).toLowerCase()

        console.log(window.location.pathname.substring(13));

        if(pages.hasOwnProperty(path)){
            setPageState(pages[path])
        }else{
            setTimeout(() => {
              window.location.replace('/');
            }, 3000);
            setMessageState("Page does not exist.&#10;Will redirect in 3 seconds...")
        }
    },[setMessageState])

    return <>{pageState}</>
}

const pages = {
    multiplayerfps: <div id="Single-Page-Display" className="Padding-Top-1">
        <div className="Single-Page-Display-Element">
            <img
                style={{width:'100%'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682044328/NichathanGaming%20Website%20Assets/Commission%20Assets/MultiplayerFPS.png'
                alt='logo'
            />
            <h1>Multiplayer FPS</h1>
            <p>My first commission was to create a 3D multiplayer game for the android device. The client provided the 3D assets and some simple UI elements. Over 3 days, Nichathan Gaming created extra UI elements for the display, edited the animations to be compatible with PUN2, set up the UI for Android use, implemented PUN2 with the game and tested the app.</p>
        </div>
    </div>,
    pmship: <div id="Single-Page-Display" className="Padding-Top-1">
        <div className="Single-Page-Display-Element">
            <img
                style={{width:'100%'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682043676/NichathanGaming%20Website%20Assets/Commission%20Assets/PMShip.png'
                alt='logo'
            />
            <h1>PMShip</h1>
            <p>PMShip is an ongoing project. PMShip is a 2D strategy game where the player must survive 2 terms as a prime minister. The client has provided the ideas and the art assets but Nichathan Gaming is giving everything functionality and programming the AI and game mathematics.</p>
        </div>
    </div>,
    bingogenerator: <div id="Single-Page-Display" className="Padding-Top-1">
        <h1>Bingo Generator</h1>
        <p>The Bingo Generator was a React project that posed as a learning moment for Nichathan Gaming. When this work was commissioned, the vision of the client was unclear and changed day by day. The commission was eventually completed despite the language barrier and the client not having a clear idea of what was wanted.</p>
        <iframe autoPlay 
            style={{position:'relative', height:'55rem',zIndex:1}}
            src="https://nichathan-gaming.github.io/BingoGenerator/" 
            title="Bingo Generator" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
    </div>
}