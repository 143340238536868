import HoverItemElement, { HoverItemElementIMG } from "../../components/HoverItemElement";

export default function Games(){
    return <div id="Page-Display">
        <h1>Games</h1>
        <div className='Seperator-Bar' />     
        <div className='Home-Elements'>
            <a href="/Games/Catastrophic"><HoverItemElement 
                headerText={'Catastrophic'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681965520/Catastrophic/Google_Logo.png'}
                vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958654/NichathanGaming%20Website%20Assets/DisplayVideos/Catastrophic_Demo.mp4#t=8"}
            /></a>
            <a href="/Games/Snake"><HoverItemElement 
                headerText={'Snake'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981947/NichathanGaming%20Website%20Assets/Icons/SnakeLogo.png'}
                vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958655/NichathanGaming%20Website%20Assets/DisplayVideos/Snake.mp4#t=8"}
            /></a>
            <a href="/Games/Solitaire"><HoverItemElement 
                headerText={'Solitaire'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981746/NichathanGaming%20Website%20Assets/Icons/Logo2023.png'}
                vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958654/NichathanGaming%20Website%20Assets/DisplayVideos/Nichathan_s_Solitaire_Pack_Gameplay.mp4#t=8"}
            /></a>
            <a href="/Games/Pathfinder"><HoverItemElement 
                headerText={'Pathfinder'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981737/NichathanGaming%20Website%20Assets/Icons/Pathfinder.png'}
                vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958654/NichathanGaming%20Website%20Assets/DisplayVideos/Pathfinder.mp4#t=8"}
            /></a>
            <a href="/Games/TheChosenOne"><HoverItemElementIMG 
                headerText={'TheChosenOne'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062702/NichathanGaming%20Website%20Assets/Games%20Icons/Google_Logo.png'}
            /></a>
            <a href="/Games/RandomNumberGenerator"><HoverItemElement 
                headerText={'Random Number Generator'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1682084635/NichathanGaming%20Website%20Assets/Games%20Icons/unnamed.png'}
                vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958656/NichathanGaming%20Website%20Assets/DisplayVideos/Nichathan_Gaming_s_RNG_App.mp4#t=8"}
            /></a>
            <a href="/Games/SocialMatch3"><HoverItemElementIMG 
                headerText={'Social Match 3'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062514/NichathanGaming%20Website%20Assets/Games%20Icons/Match3LogoNew.png'}
            /></a>
            <a href="/Games/InfiniteRunner"><HoverItemElement 
                headerText={'Infinite Runner'}
                buttonText={'View'}
                imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062596/NichathanGaming%20Website%20Assets/Games%20Icons/Logo.png'}
                vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1682128936/NichathanGaming%20Website%20Assets/DisplayVideos/Infinite_Runner.mp4#t=8"}
            /></a>
        </div>  
    </div>
}