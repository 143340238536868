import { useState } from "react"
import Glitch from "../animations/Glitch"

export default function HoverItemElement({id, className, headerText, buttonText, imgURL, vidURL}){
    //Pathfinder
    const img = <img alt="disp-img"
        src={imgURL}
    />
    //Pathfinder
    const vid = <video autoPlay loop muted
        src={vidURL}
        type="video/mp4"
    />

    const [elementState, setElementState] = useState(img)

    return <div 
        id={id}
        className={'Home-Game-Element '+className}
        onMouseEnter={()=>{setElementState(vid)}}
        onMouseLeave={()=>{setElementState(img)}}
    >
        {elementState}
        <h2 className='Home-Game-Element-Title'>{headerText}</h2>
        <Glitch  
            className='Home-Header-Button'
            params={buttonText}
        />
    </div>
}

export function HoverItemElementIMG({id, className, headerText, buttonText, imgURL}){
    return <div 
        id={id}
        className={'Home-Game-Element '+className}
    >
        <img alt="disp-img"
            src={imgURL}
        />
        <h2 className='Home-Game-Element-Title'>{headerText}</h2>
        <Glitch  
            className='Home-Header-Button'
            params={buttonText}
        />
    </div>
}

export function HoverItemElementText({id, className, headerText, buttonText}){
    return <div 
        id={id}
        className={'Home-Game-Element '+className}
    >
        <img 
            style={{backgroundColor:'#00000080'}}
            alt="disp-img"
            src={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681881636/NichathanGaming%20Logos/NichathanGamingLogoWhiteSmall.png'}
        />
        <h2 className='Home-Game-Element-Title'>{headerText}</h2>
        <Glitch  
            className='Home-Header-Button'
            params={buttonText}
        />
    </div>
}