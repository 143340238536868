import { useContext, createContext, useRef, useState, useEffect } from "react";

const NotificationContext = createContext()

export const NotificationContextProvider = ({children})=>{
    const snackRef = useRef(null)
    const [messageState, setMessageState] = useState('')

    useEffect(()=>{
        if(messageState!==null && messageState.length>0){
            snackRef.current.innerHTML = "<p>"+messageState+"</p>"
            snackRef.current.className = "show"
            setTimeout(()=>{ snackRef.current.className = snackRef.current.className.replace("show", ""); }, 2900);
            setMessageState('')
        }
    },[messageState])

    return <NotificationContext.Provider value={{
        setMessageState
    }}>
        {children}
        <div id="Snackbar" ref={snackRef} />
    </NotificationContext.Provider>
}

export const useNotificationInfo = ()=>{return useContext(NotificationContext)}