import Glitch from "../../animations/Glitch";
import { HoverItemElementIMG, HoverItemElementText } from "../../components/HoverItemElement";

export default function Tutorials() {
    console.log('tutorials/Tutorials.jsx');
    return <div id="Page-Display">
        <h1>Tutorials</h1>
        <div className='Seperator-Bar' />   
        <Glitch params={
            <a 
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                href="https://www.youtube.com/@nichathangaming"
            > View More at <img
                alt='youtube'
                src="https://img.icons8.com/color/96/null/youtube-play.png"
            /></a>
        }/>
        
        <div className='Home-Elements'>
            <a href="/Tutorials/GooglePlayAuth"><HoverItemElementIMG 
                imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682045429/NichathanGaming%20Website%20Assets/Tutorial%20Assets/GooglePlayAuth.png"}
                headerText={'Google Play Auth'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/MazeGenerator"><HoverItemElementIMG 
                imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682048061/NichathanGaming%20Website%20Assets/Tutorial%20Assets/MazeGenerator.png"}
                headerText={'Maze Generator'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/UnityAspectRatio"><HoverItemElementIMG 
                imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682048060/NichathanGaming%20Website%20Assets/Tutorial%20Assets/ForceAspectRatio.png"}
                headerText={'Unity Aspect Ratio'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/ConnectTheDots"><HoverItemElementIMG 
                imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682048084/NichathanGaming%20Website%20Assets/Tutorial%20Assets/ConnectTheDots.png"}
                headerText={'Connect The Dots'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/Unity2DAnimations"><HoverItemElementText 
                headerText={'Unity 2D Animations'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/UnityHubProjectFolderFailedFix"><HoverItemElementText 
                headerText={'Unity Hub Project Folder Failed Fix'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/UnityFontAssets"><HoverItemElementText 
                headerText={'Unity Font Assets'}
                buttonText={'View'}
            /></a>            
            <a href="/Tutorials/ExeToInstaller"><HoverItemElementIMG 
                imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682137471/NichathanGaming%20Website%20Assets/Tutorial%20Assets/UnityEXEtoInstaller.png"}
                headerText={'Exe to Installer'}
                buttonText={'View'}
            /></a>
            <a href="/Tutorials/FirebaseRules"><HoverItemElementText 
                headerText={'Firebase Rules'}
                buttonText={'View'}
            /></a>  
        </div>   
    </div>
}