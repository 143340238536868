import { useEffect, useState } from "react";
import { useNotificationInfo } from "../../contexts/NotificationContext";
import Glitch from "../../animations/Glitch";

export default function Tutorial(){
    const {setMessageState} = useNotificationInfo()
    const [pageState, setPageState] = useState(<></>)

    useEffect(()=>{
        const path = window.location.pathname.substring(11).toLowerCase()

        if(pages.hasOwnProperty(path)){
            setPageState(pages[path])
        }else{
            setTimeout(() => {
              window.location.replace('/');
            }, 3000);
            setMessageState("Page does not exist.&#10;Will redirect in 3 seconds...")
        }
    },[setMessageState])

    return <>{pageState}</>
}

const pages = {
    googleplayauth: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/XxzPdCspDUU?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Google Play Auth</h1>
            <p>This tutorial details how to add Google Play Authentication to a Unity application.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://github.com/Nichathan-Gaming/GooglePlayAuthenticationInUnityWithFirebase">
                <img
                    alt='github'
                    src="https://img.icons8.com/color/96/null/github--v1.png"
                />
            </a>}/>
            <Glitch params={<a href="https://www.youtube.com/watch?v=XxzPdCspDUU">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>,
    mazegenerator: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/5H8DvFP_R7I?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>5 Maze Generator Algorithms</h1>
            <p>This tutorial details how to create 5 different maze generators.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://github.com/Nichathan-Gaming/RandomMazeGenerators">
                <img
                    alt='github'
                    src="https://img.icons8.com/color/96/null/github--v1.png"
                />
            </a>}/>
            <Glitch params={<a href="https://www.youtube.com/watch?v=5H8DvFP_R7I">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
        <iframe autoPlay 
            style={{position:'relative', height:'45rem',zIndex:1}}
            src="https://nichathan-gaming.github.io/RandomMazeGenerators/" 
            title="Maze Generators" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
    </div>,
    unityaspectratio: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/xZdW-avT5UA?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Force Aspect Ratio</h1>
            <p>This tutorial details a unique and alternate method to force an aspect ratio in a Unity project.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://github.com/Nichathan-Gaming/Unity-Force-Aspect-Ratio-2D-3D">
                <img
                    alt='github'
                    src="https://img.icons8.com/color/96/null/github--v1.png"
                />
            </a>}/>
            <Glitch params={<a href="https://www.youtube.com/watch?v=xZdW-avT5UA">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>,
    connectthedots: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/PZQK4ADyqdY?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Connect the dots</h1>
            <p>This tutorial details how to create a connect the dots game.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://github.com/Nichathan-Gaming/ConnectTheDots">
                <img
                    alt='github'
                    src="https://img.icons8.com/color/96/null/github--v1.png"
                />
            </a>}/>
            <Glitch params={<a href="https://www.youtube.com/watch?v=PZQK4ADyqdY">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
        <iframe autoPlay 
            style={{position:'relative', height:'45rem',zIndex:1}}
            src="https://nichathan-gaming.github.io/ConnectTheDots/" 
            title="Pathfinder" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
    </div>,
    unity2danimations: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/wEsq5vhg4VQ?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Unity 2D Animations</h1>
            <p>This tutorial describes how to handle simple 2D animations in Unity.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://www.youtube.com/watch?v=wEsq5vhg4VQ">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>,
    unityhubprojectfolderfailedfix: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/7qSUmHBG7O8?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Unity Hub Project Folder Failed Fix</h1>
            <p>This tutorial describes how to fix the Unity hub project folder failed issue.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://www.youtube.com/watch?v=7qSUmHBG7O8">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>,
    unityfontassets: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/iqGgiQ0O1zo?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Unity Font Assets</h1>
            <p>This tutorial describes how handle font assets in Unity.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://www.youtube.com/watch?v=iqGgiQ0O1zo">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>,
    exetoinstaller: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/x2pNuDhwS3Q?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Exe to Installer</h1>
            <p>This tutorial details how to convert a .exe file to an installer.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://github.com/Nichathan-Gaming/UnityExeToInstaller/blob/master/README.md">
                <img
                    alt='github'
                    src="https://img.icons8.com/color/96/null/github--v1.png"
                />
            </a>}/>
            <Glitch params={<a href="https://www.youtube.com/watch?v=x2pNuDhwS3Q">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>,
    firebaserules: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/0dvvxCQaZGQ?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element">
            <h1>Firebase Rules</h1>
            <p>This tutorial explains some basic rules for the Firebase Realtime Database.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <Glitch params={<a href="https://www.youtube.com/watch?v=0dvvxCQaZGQ">
                <img
                    alt='youtube'
                    src="https://img.icons8.com/color/96/null/youtube-play.png"
                />
            </a>}/>
        </div>
    </div>
}