import Glitch from "../../../animations/Glitch";

export default function Media() {
    return <div id="Home-Media">
        <Glitch params={<a href="https://www.facebook.com/profile.php?id=100092064726023">
            <img 
                alt="facebook"
                src="https://img.icons8.com/color/98/000000/facebook-new.png"
            />
        </a>}/>
        <Glitch params={<a href="https://twitter.com/Johnacrossworld">
            <img
                alt='twitter'
                src="https://img.icons8.com/color/98/null/twitter--v1.png"
            />
        </a>}/>
        <Glitch params={<a href="https://github.com/Nichathan-Gaming">
            <img
                alt='github'
                src="https://img.icons8.com/color/96/null/github--v1.png"
            />
        </a>}/>
        <Glitch params={<a href="https://www.youtube.com/@nichathangaming">
            <img
                alt='youtube'
                src="https://img.icons8.com/color/96/null/youtube-play.png"
            />
        </a>}/>
        <Glitch params={<a href="https://www.linkedin.com/in/johnathan-nichols-571b87272/">
            <img
                alt='linkedin'
                src="https://img.icons8.com/color/96/null/linkedin.png"
            />
        </a>}/>
        <Glitch params={<a href="https://www.twitch.tv/nichathan">
            <img
                alt='twitch'
                src="https://img.icons8.com/color/96/null/twitch--v1.png"
            />
        </a>}/>
    </div>    
}