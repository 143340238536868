import { HoverItemElementIMG } from "../../../components/HoverItemElement"

export default function Tutorials() {
    return <div className='Home-Elements'>
        <a href="/Tutorials/GooglePlayAuth"><HoverItemElementIMG 
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682045429/NichathanGaming%20Website%20Assets/Tutorial%20Assets/GooglePlayAuth.png"}
            headerText={'Google Play Auth'}
            buttonText={'View'}
        /></a>
        <a href="/Tutorials/MazeGenerator"><HoverItemElementIMG 
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682048061/NichathanGaming%20Website%20Assets/Tutorial%20Assets/MazeGenerator.png"}
            headerText={'Maze Generator'}
            buttonText={'View'}
        /></a>
        <a href="/Tutorials/UnityAspectRatio"><HoverItemElementIMG 
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682048060/NichathanGaming%20Website%20Assets/Tutorial%20Assets/ForceAspectRatio.png"}
            headerText={'Unity Aspect Ratio'}
            buttonText={'View'}
        /></a>
        <a href="/Tutorials/ConnectTheDots"><HoverItemElementIMG 
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682048084/NichathanGaming%20Website%20Assets/Tutorial%20Assets/ConnectTheDots.png"}
            headerText={'Connect The Dots'}
            buttonText={'View'}
        /></a>
    </div>  
}