import HoverItemElement from "../../../components/HoverItemElement"

export default function Games() {
    return <div className='Home-Elements'>
        <a href="/Games/Catastrophic"><HoverItemElement 
            headerText={'Catastrophic'}
            buttonText={'View'}
            imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681965520/Catastrophic/Google_Logo.png'}
            vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958654/NichathanGaming%20Website%20Assets/DisplayVideos/Catastrophic_Demo.mp4#t=8"}
        /></a>
        <a href="/Games/Snake"><HoverItemElement 
            headerText={'Snake'}
            buttonText={'View'}
            imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981947/NichathanGaming%20Website%20Assets/Icons/SnakeLogo.png'}
            vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958655/NichathanGaming%20Website%20Assets/DisplayVideos/Snake.mp4#t=8"}
        /></a>
        <a href="/Games/Solitaire"><HoverItemElement 
            headerText={'Solitaire'}
            buttonText={'View'}
            imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981746/NichathanGaming%20Website%20Assets/Icons/Logo2023.png'}
            vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958654/NichathanGaming%20Website%20Assets/DisplayVideos/Nichathan_s_Solitaire_Pack_Gameplay.mp4#t=8"}
        /></a>
        <a href="/Games/Pathfinder"><HoverItemElement 
            headerText={'Pathfinder'}
            buttonText={'View'}
            imgURL={'https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981737/NichathanGaming%20Website%20Assets/Icons/Pathfinder.png'}
            vidURL={"https://res.cloudinary.com/nichathan-gaming/video/upload/v1681958654/NichathanGaming%20Website%20Assets/DisplayVideos/Pathfinder.mp4#t=8"}
        /></a>
    </div>  
}