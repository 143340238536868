import './App.css'
import Footer from './Footer'
import Games from './pages/games/Games'
import Home from './pages/home/Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Tutorials from './pages/tutorials/Tutorials'
import Game from './pages/games/Game'
import { NotificationContextProvider } from './contexts/NotificationContext'
import Tutorial from './pages/tutorials/Tutorial'
import Commission from './pages/commissions/Commission'

function App() {
  return <>
  <video autoPlay loop muted
    style={{
      zIndex: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      position: 'fixed',
      borderRadius:0
    }}
    src="https://res.cloudinary.com/nichathan-gaming/video/upload/v1681878341/NichathanGaming%20Website%20Assets/SpaceStars.mp4"
    type="video/mp4"
  />
  <NotificationContextProvider>
    <Router>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route exact path="/Games" element={<Games />} />
        <Route exact path="/Games/*" element={<Game />} />
        <Route exact path="/Tutorials" element={<Tutorials />} />
        <Route exact path="/Tutorials/*" element={<Tutorial />} />
        <Route exact path="/Commissions/*" element={<Commission />} />
      </Routes>
      <Footer/>
    </Router>
  </NotificationContextProvider>
</>
}

export default App;
