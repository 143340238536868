import { useRef } from 'react'
import Glitch from '../../animations/Glitch'
import Games from './tabs/Games'
import Commissions from './tabs/Commissions'
import Tutorials from './tabs/Tutorials'
import Media from './tabs/Media'
import Contact from './tabs/Contact'
import Info from './tabs/Info'

export default function Home() {
    const topRef = useRef(null)
    const gamesRef = useRef(null)
    const commissionsRef = useRef(null)
    const tutorialsRef = useRef(null)
    const mediaRef = useRef(null)
    const contactRef = useRef(null)
    const infoRef = useRef(null)

    return <div id='Home-Container' ref={topRef}>
        <Glitch id='Home-To-Top' params={<div >Top</div>} onClick={() => { topRef.current.scrollIntoView({ behavior: 'smooth' })}}/>

        <div id='Home-Nav'>
            <div className='Home-Nav-Element'>
                <Glitch
                    className='Home-Nav-Element-Left'
                    params='Games'
                    onClick={() => { gamesRef.current.scrollIntoView({ behavior: 'smooth' })}}
                />
                <Glitch
                    className='Home-Nav-Element-Center Home-Nav-Element-Left'
                    params='Commissions'
                    onClick={() => { commissionsRef.current.scrollIntoView({ behavior: 'smooth' }) }}
                />
                <Glitch
                    className='Home-Nav-Element-Left'
                    params='Tutorials'
                    onClick={() => { tutorialsRef.current.scrollIntoView({ behavior: 'smooth' }) }}
                />
            </div>
            <img
                id='Home-IMG'
                alt="home-Img"
                src="https://res.cloudinary.com/nichathan-gaming/image/upload/o_80/v1681881635/NichathanGaming%20Logos/NichathanGamingLogoWhite.png"
            />
            <div className='Home-Nav-Element'>
                <Glitch
                    className='Home-Nav-Element-Right'
                    params='Media'
                    onClick={() => { mediaRef.current.scrollIntoView({ behavior: 'smooth' }) }}
                />
                <Glitch
                    className='Home-Nav-Element-Center Home-Nav-Element-Right'
                    params='Contact'
                    onClick={() => { contactRef.current.scrollIntoView({ behavior: 'smooth' }) }}
                />
                <Glitch
                    className='Home-Nav-Element-Right'
                    params='Info'
                    onClick={() => { infoRef.current.scrollIntoView({ behavior: 'smooth' }) }}
                />
            </div>
        </div>
        
        <div className='Seperator-Bar' ref={gamesRef} />       
        <div className='Home-Header'>
            <h2>Games</h2>
            <a href='/Games'><Glitch 
                params={<img 
                    className='Home-Header-Image'
                    alt='more-button'
                    src="https://img.icons8.com/pastel-glyph/64/null/plus--v1.png"
                />} 
            /></a>
        </div> 
        <Games/>
        
        <div className='Seperator-Bar' ref={commissionsRef}/>      
        <div className='Home-Header'>
            <h2>Commissions</h2>
            {/* <a href='/Commissions'><Glitch 
                params={<img 
                    className='Home-Header-Image'
                    alt='more-button'
                    src="https://img.icons8.com/pastel-glyph/64/null/plus--v1.png"
                />} 
            /></a> */}
        </div>
        <Commissions/>

        <div className='Seperator-Bar' ref={tutorialsRef}/>             
        <div className='Home-Header'>
            <h2>Tutorials</h2>
            <a href='/Tutorials'><Glitch 
                params={<img 
                    className='Home-Header-Image'
                    alt='more-button'
                    src="https://img.icons8.com/pastel-glyph/64/null/plus--v1.png"
                />} 
            /></a>
        </div> 
        <Tutorials/>

        <div className='Seperator-Bar' ref={mediaRef}/>             
        <div className='Home-Header'>
            <h2>Media</h2>
        </div> 
        <Media/>

        <div className='Seperator-Bar' ref={contactRef}/>      
        <div className='Home-Header'>
            <h2>Contact</h2>
        </div>        
        <Contact/>

        <div className='Seperator-Bar' ref={infoRef}/>      
        <div className='Home-Header'>
            <h2>Info</h2>
        </div>              
        <Info/>
    </div>
}