import { useEffect, useState } from "react";
import { useNotificationInfo } from "../../contexts/NotificationContext";

export default function Game(){
    const {setMessageState} = useNotificationInfo()
    const [pageState, setPageState] = useState(<></>)

    useEffect(()=>{
        const path = window.location.pathname.substring(7).toLowerCase()

        if(pages.hasOwnProperty(path)){
            setPageState(pages[path])
        }else{
            setTimeout(() => {
              window.location.replace('/');
            }, 3000);
            setMessageState("Page does not exist.&#10;Will redirect in 3 seconds...")
        }
    },[setMessageState])

    return <>{pageState}</>
}

const pages = {
    catastrophic: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/v97MES2qVHA?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element-Reverse">
            <img
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1681965520/Catastrophic/Google_Logo.png'
                alt='logo'
            />
            <div className="Single-Page-Display-Text">
                <h1>Catastrophic</h1>
                <p>Catastrophic is a side scrolling infinite runner game where you play as a high school girl named Paige.</p>
            </div>
        </div>
        <div className="Single-Page-Display-Element">
            <div>
                <h2>Meet Paige</h2>
                <img
                    style={{backgroundColor:'#ffffff80'}}
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1676126448/Catastrophic/paige/paige_idle_3.png"
                    alt="Paige"
                />
            </div>
            <p className="Single-Page-Display-Description">Paige must dodge the cats on her way to school for as long as possible but she will inevitably be distracted by the cats and not make it to school on time.</p>
        </div>
        
        <h2>Meet The Cats</h2>
        <div className="Single-Page-Display-Element">
            <div>
                <h3>Cat Stack</h3>
                <img
                    style={{backgroundColor:'#ffffff80'}}
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1676144668/Catastrophic/obstacle/tall_obstacle_1.png"
                    alt="Cat Stack"
                />
            </div>
            <p className="Single-Page-Display-Description">The cats have teamed up to try their best to distract Paige. Be careful because the bottom most cat cannot see where it is going! You must jump at the right time to avoid this tall obstacle.</p>
        </div>
        <div className="Single-Page-Display-Element-Reverse">
            <div>
                <h3>Clumsy Cat</h3>
                <img
                    style={{backgroundColor:'#ffffff80'}}
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1676144669/Catastrophic/obstacle/fly_obstacle_7.png"
                    alt="Clumsy Cat"
                />
            </div>
            <p className="Single-Page-Display-Description">This cat looks unassuming but due to its clumsy nature, it will trip and be sent flying towards Paige's head. The clumsy cat cannot be jumped over, Paige must slide under it.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <div>
                <h3>Yarn Cat</h3>
                <img 
                    style={{backgroundColor:'#ffffff80'}}
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1676144668/Catastrophic/obstacle/small_obstacle_6.png"
                    alt="Yarn Cat"
                />
            </div>
            <p className="Single-Page-Display-Description">The yarn cat has accidentally become tangled in a ball of yarn and in its futile efforts to free itself, it has begun hurtling towards Paiges ankles.</p>
        </div>
        <h2>Discover The Locations</h2>
        <h3>Home</h3>
        <p>Paige always starts her day at home as she is on her way to class but in her effort to avoid the distracting cats around town, she may end up where she started.</p>
        <h3>The Park</h3>
        <p>Hoping to take a shortcut to school, Paige travels through the park only to find it filled with very distracting cats.</p>
        <h3>The School</h3>
        <p>Paige has finally arrived at school, can she find her class or will she accidentally leave school while trying to avoid the cats?</p>
        <p>More Details Coming soon...</p>
    </div>,
    snake: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/dYvTluhv01o?start=7" 
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.Snake"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062627/NichathanGaming%20Website%20Assets/Games%20Icons/SnakeLogo.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>Snake</h1>
                <p>A classic snake game with multiple control options.</p>
            </div>
        </div>
        <h2>Control Options</h2>
        <ul>
            <li>Swipe</li>
            <li>On Screen Buttons</li>
            <li>Controller</li>
        </ul>
        <a 
            id="Link-A"
            href="https://play.google.com/store/apps/details?id=com.NichathanGaming.Snake"
        >Download from Google Play</a>
    </div>,
    solitaire: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/Om2vqyIIwQQ?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.NichathansSolitairePack"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981746/NichathanGaming%20Website%20Assets/Icons/Logo2023.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>Solitaire</h1>
                <p>Nichathan Gaming's solitaire pack comes with 6 exciting card games including the game Black War which is a game invented by John of Nichthan Gaming.</p>
            </div>
        </div>
        <div className="Single-Page-Display-Element">
            <div>
                <h2>Klondike</h2>
                <img
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1682081642/NichathanGaming%20Website%20Assets/Solitaire%20Icons/Klondike.png"
                    alt="Klondike"
                />
            </div>
            <p className="Single-Page-Display-Description">Klondike Solitaire is a classic card game where the player must complete runs from king to ace for all 4 suits.</p>
        </div>
        <div className="Single-Page-Display-Element-Reverse">
            <div>
                <h3>Pyramid</h3>
                <img
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1682081642/NichathanGaming%20Website%20Assets/Solitaire%20Icons/Pyramid.png"
                    alt="Pyramid"
                />
            </div>
            <p className="Single-Page-Display-Description">To win a game of Pyramid Solitaire, the player must match the cards to clear the screen. The matches must equal 13.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <div>
                <h3>Tri Peaks</h3>
                <img 
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1682081642/NichathanGaming%20Website%20Assets/Solitaire%20Icons/TriPeaks.png"
                    alt="Tri Peaks"
                />
            </div>
            <p className="Single-Page-Display-Description">To win a game of Tri Peaks Solitaire, the player must clear all of the cards from the game area by chosing a card 1 value below or above the active card.</p>
        </div>
        <div className="Single-Page-Display-Element-Reverse">
            <div>
                <h3>Spider</h3>
                <img
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1682081642/NichathanGaming%20Website%20Assets/Solitaire%20Icons/Spider.png"
                    alt="Spider"
                />
            </div>
            <p className="Single-Page-Display-Description">To win a game of Spider Solitaire, the player must clear all of the cards from the screen by making runs from king to ace.</p>
        </div>
        <div className="Single-Page-Display-Element">
            <div>
                <h3>Free Cell</h3>
                <img 
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1682081642/NichathanGaming%20Website%20Assets/Solitaire%20Icons/FreeCell.png"
                    alt="Free Cell"
                />
            </div>
            <p className="Single-Page-Display-Description">To win a game of Free Cell, the player must clear all of the cards from the game area by making runs from king to ace.</p>
        </div>
        <div className="Single-Page-Display-Element-Reverse">
            <div>
                <h3>Black War</h3>
                <img
                    src="https://res.cloudinary.com/nichathan-gaming/image/upload/v1682081642/NichathanGaming%20Website%20Assets/Solitaire%20Icons/BlackWar.png"
                    alt="Black War"
                />
            </div>
            <p className="Single-Page-Display-Description">To win a game of Black War, the player must have a hand as close as possible to 21 but not over. In case of a tie, the tied winners must draw to resolve the tie.</p>
        </div>
    </div>,
    pathfinder: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/-2yVFhUJShM?start=7" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.Pathfinder"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1681981737/NichathanGaming%20Website%20Assets/Icons/Pathfinder.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>Pathfinder</h1>
                <p>Pathfinder is a classic puzzle game where the player must find the paths between 2 points to fill every cell on the board.</p>
            </div>
        </div>
        <iframe autoPlay 
            style={{position:'relative', height:'45rem',zIndex:1}}
            src="https://nichathan-gaming.github.io/ConnectTheDots/" 
            title="Pathfinder" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
    </div>,
    thechosenone: <div id="Single-Page-Display" className="Padding-Top-1">
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.TheChosenOne"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062702/NichathanGaming%20Website%20Assets/Games%20Icons/Google_Logo.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>The Chosen One</h1>
                <p>The Chosen One is a fun party game only for Android devices. The players put a finger on the screen and whoever is chosen must preform a predetermined task or take a punishment.</p>
            </div>
        </div>
    </div>,
    randomnumbergenerator: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/hskGnaxO3C4?start=7" 
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.NichathanGamingsRandomNumberGenerator"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682084635/NichathanGaming%20Website%20Assets/Games%20Icons/unnamed.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>Random Number Generator</h1>
                <p>Random Number Generator is a 6 part application with many functions to support game play.</p>
            </div>
        </div>
        <div className="Single-Page-Display-Element">
            <div style={{width: '45%', overflowWrap: 'anywhere', padding: '1rem'}}>
                <h2>Dice Roller</h2>
                <p>Dice Roller allows the player to roll many different die.</p>
            </div>
            <div style={{width: '45%', overflowWrap: 'anywhere', padding: '1rem'}}>
                <h2>Rand Numb</h2>
                <p>Rand Numb chooses a random number between a range of numbers.</p>
            </div>
            <div style={{width: '45%', overflowWrap: 'anywhere', padding: '1rem'}}>
                <h2>Coin Flipper</h2>
                <p>Coin Flipper will flip a coin.</p>
            </div>
            <div style={{width: '45%', overflowWrap: 'anywhere', padding: '1rem'}}>
                <h2>Word Picker</h2>
                <p>Word Picker will choose 1 or more random words from a list of words.</p>
            </div>
            <div style={{width: '45%', overflowWrap: 'anywhere', padding: '1rem'}}>
                <h2>Card Picker</h2>
                <p>Card Picker will choose 1 or more random playing cards.</p>
            </div>
        </div>
    </div>,
    socialmatch3: <div id="Single-Page-Display" className="Padding-Top-1">
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.NichathansMatch3"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062514/NichathanGaming%20Website%20Assets/Games%20Icons/Match3LogoNew.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>Social Match 3</h1>
                <p>Social Match 3 is a match 3 game inspired by Bejeweled with social aspects to it.</p>
            </div>
        </div>
    </div>,
    infiniterunner: <div id="Single-Page-Display" className="Padding-Top-25">
        <iframe autoPlay 
            src="https://www.youtube.com/embed/7Fm7yfhnpaA?start=7" 
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
        <div className="Single-Page-Display-Element-Reverse">
            <a href="https://play.google.com/store/apps/details?id=com.NichathanGaming.InfiniteRunner"><img
                style={{backgroundColor:'transparent'}}
                src='https://res.cloudinary.com/nichathan-gaming/image/upload/v1682062596/NichathanGaming%20Website%20Assets/Games%20Icons/Logo.png'
                alt='logo'
            /></a>
            <div className="Single-Page-Display-Text">
                <h1>Infinite Runner</h1>
                <p>Infinite Runner is a simple hand drawn infinite runner game.</p>
            </div>
        </div>
    </div>
}