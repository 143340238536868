import { HoverItemElementIMG } from "../../../components/HoverItemElement"

export default function Commissions() {
    return <div className='Home-Elements'>
        <a href="/Commissions/MultiplayerFPS"><HoverItemElementIMG
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682044328/NichathanGaming%20Website%20Assets/Commission%20Assets/MultiplayerFPS.png"}
            headerText={'Multiplayer FPS'}
            buttonText={'View'}
        /></a>
        <a href="/Commissions/PMShip"><HoverItemElementIMG
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682043676/NichathanGaming%20Website%20Assets/Commission%20Assets/PMShip.png"}
            headerText={'PMShip'}
            buttonText={'View'}
        /></a>
        <a href="/Commissions/BingoGenerator"><HoverItemElementIMG
            imgURL={"https://res.cloudinary.com/nichathan-gaming/image/upload/v1682040149/NichathanGaming%20Website%20Assets/Commission%20Assets/BingoGenerator.png"}
            headerText={'Bingo Generator'}
            buttonText={'View'}
        /></a>
    </div>  
}